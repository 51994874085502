/* eslint no-console:0 */
const Rails = require('@rails/ujs');

Rails.start();

require('stylesheets/ecppp/application.sass');

require('shared/components/accordion');
require('shared/components/checkbox_for_button');
require('shared/components/dropdown');
require('shared/components/burger_menu');
require('shared/components/modal');
require('shared/slider');
require('shared/klaro-config');
require('shared/stimulus');

require('ecs/date_input_select');
require('ecs/loader');
require('ecs/login');
require('ecs/password_field');
require('ecs/file-downloader');

require('ecppp/account');
require('ecppp/parrainage');
require('ecppp/site_selector');
require('ecppp/mon_suivi_conso.js');
require('ecppp/resiliation.js');
require('ecppp/tracking_events.js');
